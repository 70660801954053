import './App.css';
import LogInWrapper from "./components/LogInWrapper";
import LogOutWrapper from "./components/LogOutWrapper";

function App() {

	const searchParams = new URLSearchParams(document.location.search)
	if (searchParams.get("action") === 'logout') {
		console.log("WRAPPER")
		return <LogOutWrapper />
	}else{
		return <LogInWrapper/>;
	}

}

export default App;
